<template>
  <div class="main-panel">
    <h1>Open Tickets</h1>
    <div>
      <select :class="{ 'not-placeholder': filterBy != '' }" v-model="filterBy">
        <option value="" disabled>assigned to</option>
        <option value="all">Show All</option>
        <option v-for="user in globalState.users" :key="user.userid" :value="user.userid">{{ user.username }}</option>
      </select>
    </div>
  </div>
  <div class="open-tickets">
    <img class="action-btn" @click="$router.push({ name: 'Ticket' })" src="../assets/plus.svg" alt="" />
    <img class="action-btn reload" @click="getOpenTickets" src="../assets/reload.svg" alt="" />
    <div class="tickets">
      <div
        class="ticket"
        v-for="ticket in sortedTickets"
        :key="ticket.ticketid"
        @mouseenter="hoveredTicket = ticket.ticketid"
        @mouseleave="hoveredTicket = ''"
      >
        <div
          @click="$router.push({ name: 'Ticket', params: { id: ticket.ticketid } })"
          :class="{ id: true, red: ticket.priority == 3, yellow: ticket.priority == 2 }"
        >
          #{{ ticket.ticketnumber }}
        </div>
        <div @click="$router.push({ name: 'Ticket', params: { id: ticket.ticketid } })" class="name">
          {{ ticket.clientname }}
        </div>
        <div @click="$router.push({ name: 'Ticket', params: { id: ticket.ticketid } })" class="disc">
          {{ ticket.description }}
        </div>
        <div @click="$router.push({ name: 'Ticket', params: { id: ticket.ticketid } })" class="cmds" v-if="ticketTimer">
          <img class="action-btn" src="../assets/stop.svg" alt="" />
          <img class="action-btn" src="../assets/pause.svg" alt="" />
          <img class="action-btn" src="../assets/discard.svg" alt="" />
        </div>
        <div class="cmds" v-if="getTimerIndex(ticket.ticketid) !== null">
          <img class="action-btn" @click="noteToStop = ticket.ticketid" src="../assets/stop.svg" alt="" />
          <img
            v-if="!timers[getTimerIndex(ticket.ticketid)].isPaused"
            class="action-btn"
            @click="externalPause(ticket.ticketid)"
            src="../assets/pause.svg"
            alt=""
          />
          <img v-else class="action-btn" @click="externalPause(ticket.ticketid)" src="../assets/play.svg" alt="" />
          <img
            class="action-btn"
            @click="deleteTimeEntry(getTimerIndex(ticket.ticketid))"
            src="../assets/discard.svg"
            alt=""
          />
        </div>
        <div class="cmds" v-else-if="getTimerIndex(ticket.ticketid) === null && hoveredTicket == ticket.ticketid">
          <img class="action-btn" @click="createTimer(ticket.ticketid)" src="../assets/play.svg" alt="" />
        </div>
        <div class="cmds" v-else></div>
      </div>
    </div>
  </div>
  <EnterText v-if="noteToStop" :ticketId="noteToStop" @clear="noteToStop = ''" />
</template>

<script>
import openTickets from '../composables/openTickets';
import global from '../composables/global';
import timeEntries from '../composables/timeEntries';
import EnterText from '../components/EnterText.vue';

export default {
  components: { EnterText },
  setup() {
    openTickets.getOpenTickets();
    timeEntries.getOpenTimeEntries();
    return {
      getOpenTickets: openTickets.getOpenTickets,
      sortedTickets: openTickets.sortedTickets,
      globalState: global.state,
      createTimer: timeEntries.createTimer,
      getTimerIndex: timeEntries.getTimerIndex,
      stopTimer: timeEntries.stopTimer,
      deleteTimeEntry: timeEntries.deleteTimeEntry,
      timers: timeEntries.timers,
      externalPause: timeEntries.externalPause,
      filterBy: openTickets.filterBy,
    };
  },
  data() {
    return {
      ticketTimer: false,
      hoveredTicket: '',
      noteToStop: '',
    };
  },
};
</script>

<style lang="scss" scoped>
.main-panel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.open-tickets {
  padding: 0px 70px;
}
.reload {
  padding-left: 13px;
}
.tickets {
  padding-top: 15px;
  max-width: 880px;
  margin-left: -10px;
  .ticket {
    display: flex;
    flex-direction: row;
    cursor: pointer;

    &:hover {
      background: #575757;
    }
    div {
      padding: 10px;
      font-size: 1.2em;
      text-align: left;
    }
    .id {
      width: 90px;
    }
    .name {
      flex: 3.5;
      width: 300px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .disc {
      flex: 3.5;
      width: 300px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .cmds {
      width: 120px;
      text-align: right;
      padding: 0px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding-right: 12px;
      img {
        margin-left: 12px;
        width: 25px;
        height: 25px;
      }
    }

    .red {
      color: #f66565;
    }
    .yellow {
      color: #fff8b5;
    }
  }
}
</style>
