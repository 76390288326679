<template>
  <div class="background">
    <div class="popup">
      <textarea
        @keydown.enter.prevent="submit"
        placeholder="time entry details..."
        v-model="timers[getTimerIndex(ticketId)].description"
      ></textarea>
      <button @click="submit" class="positive">SUBMIT</button>
    </div>
  </div>
</template>

<script>
import timeEntries from '../composables/timeEntries';

export default {
  props: ['ticketId'],
  setup() {
    return {
      timers: timeEntries.timers,
      getTimerIndex: timeEntries.getTimerIndex,
      stopTimer: timeEntries.stopTimer,
    };
  },
  methods: {
    submit() {
      this.stopTimer(this.getTimerIndex(this.$props.ticketId));
      this.$emit('clear');
    },
  },
};
</script>

<style lang="scss" scoped>
.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 99vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.404);

  .popup {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -200px;
    background: #dfdfdf;
    width: 400px;
    height: 200px;
    border-radius: 6px;
    box-shadow: 0 0 50px 20px black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding: 15px;
    textarea {
      width: 100%;
      resize: none;
      height: 100px;
      padding: 10px;
      font-size: 1.2em;
      border-radius: 4px;
      outline: none;
      color: #474747;
    }
  }
}
</style>
